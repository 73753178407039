import {watch} from "vue";
import type {Ref} from "vue";

const mutationNamesByField:Record<string, string> = {
  parties: 'SET_PARTIES',
  start_document_party: 'SET_AUTHOR',
  rules: 'SET_RULES',
  entries: 'SET_ENTRIES',
  selected_party: 'SET_SELECTED_PARTY',
  operations: 'SET_OPERATIONS',
  resolvedConditions: 'SET_RESOLVED_CONDITIONS',
  conditionLogs: 'SET_CONDITION_LOGS',
  showConditionLogic: 'SET_CONDITION_LOGIC',
  datasheets: 'SET_DATASHEETS'
}

type Field = keyof typeof mutationNamesByField

/**
 * @deprecated please use 'synchronizeRefs' instead
 * @example
 * before:
 *     synchronizeStoreField([AvvStore, store], 'datasheets')
 * now:
 *     synchronizeRefs([
 *       toRef(store.state, 'datasheets'),
 *       toRef(AvvStore.state, 'datasheets)
 *     ])
 *     // it will update the last ref immediately and that will trigger the update of the other refs
 *     // So in this case, it will update the store.state.datasheets to have the same value as AvvStore.state.datasheets
 * */
export function synchronizeStoreField(stores: Array<any>, field:Field){
  stores.forEach((store, index) => {
    store.watch((state, getters) => state[field], (value) => {
      const otherStores = stores.filter((s, i) => i !== index)
      updateOtherStores(otherStores, value, field)
    }, {deep: true})
  })
}

/**
 * @deprecated please use 'synchronizeRefs' instead
 * @example
 * before:
 *     synchronizeStoreField([AvvStore, store], 'datasheets')
 * now:
 *     synchronizeRefs([
 *       toRef(store.state, 'datasheets'),
 *       toRef(AvvStore.state, 'datasheets)
 *     ])
 *     // it will update the last ref immediately and that will trigger the update of the other refs
 *     // So in this case, it will update the store.state.datasheets to have the same value as AvvStore.state.datasheets
 * */
export function synchronizeMultipleStoreFields(stores:Array<any>, keys: Array<Field>){
  keys.forEach(key => synchronizeStoreField(stores, key))
}

function updateOtherStores (otherStores, value, field){
  const mutationName = findMutationName(field)
  otherStores.forEach(store => {
    store.commit(mutationName, value)
  })
}

function findMutationName(field:Field){
  return mutationNamesByField[field]
}

/**
 * Synchronizes multiple Vue.js Ref objects with each other.
 * Whenever the value of one of the Ref objects changes, the values of the other Ref objects are updated to match.
 *
 * @param {Array<Ref>} refs - The array of Ref objects to synchronize.
 * @param {object} [options] - An optional object with the following properties:
 * @param {boolean} [options.deep=true] - Whether to synchronize Ref objects deeply (i.e., recursively).
 * @param {boolean} [options.lastImmediate=true] - Whether to immediately synchronize the last Ref object when it is added.
 *
 * @example
 * // Create three Ref objects
 * const a = ref("foo");
 * const b = ref("bar");
 * const c = ref("baz");
 *
 * // Synchronize the Ref objects
 * synchronizeRefs([a, b, c], { deep: true, lastImmediate: true });
 */
export const synchronizeRefs = (refs: Array<Ref>, options = {deep: true as true | undefined, lastImmediate: true as true | undefined}) => {
  refs.forEach((ref, index) => {
    // Watch for changes to the current Ref object
    watch(ref, (value) => {
      // Update the value of the other Ref objects to match the current Ref object
      for (const r of refs) {
        // Filter out the current Ref object
        if(ref === r) continue;
        r.value = value
      }
    }, {
      // Pass in options to the watch function
      deep: options.deep,
      // If this is the last Ref object, immediately synchronize it
      immediate: options.lastImmediate ? index === refs.length - 1 : false
    })
  })
}